import { Container } from "../../styles/style";
import { Content } from "./style";
import Navbar from "../../components/Navbar";
import FirstPhoto from "../../assets/gif/livingroom.gif";
import SecondPhoto from "../../assets/gif/buildhome.gif";
import ThirdPhoto from "../../assets/gif/housesearching.gif";
import SocialMedia from "../../components/SocialMedia";
import Footer from "../../components/Footer";

function Sobre() {
  return (
    <>
      <Container>
        <Content>
          <Navbar />
          <h1>Sobre</h1>
          <section>
            <div className="quem-somos">
              <div>
                <h2>Quem Somos</h2>
                <p>
                  A <span>Moradia do Estudante</span> surgiu com o propósito de
                  suprir as necessidades dos estudantes da Unicamp que procuram
                  um ambiente agradável, com boa localização e preço justo.
                </p>
                <p>
                  Atuamos a mais de 10 anos como uma empresa especializada em
                  locação e prestação de serviços para estudantes universitários
                  utilizando o conceito de STUDENT HOUSING. Nossa missão é
                  proporcionar tranquilidade, conforto e segurança para os
                  estudantes e seus familiares.
                </p>
                <p>
                  Valorizamos oferecer imóveis em localizações privilegiadas e
                  com a infraestrutura necessária para que os estudantes possam
                  ter a melhor experiência durante a estadia na universidade.
                </p>
              </div>
              <img src={ThirdPhoto} alt="" />
            </div>

            <div className="infraestrutura">
              <div>
                <h2>Infraestrutura</h2>
                <p>
                  Todas as casas possuem sala de televisão, sala de estar, sala
                  de estudo, cozinha, lavanderia, quintal, quartos e algumas
                  possuem área de lazer com churrasqueira e piscina.
                </p>
                <p>
                  Temos também cerca elétrica, câmera de monitoramento das
                  entradas, portão eletrônico, bicicletário e vaga de garagem.
                </p>
                <ul>
                  <li className="ul-titulo">O mobiliário é composto por:</li>
                  <li>
                    Sala com sofás, hack, televisores e mesas para estudo e
                    jantar.
                  </li>
                  <li>
                    Cozinha planejada com mesa, micro-ondas, fogão, geladeira,
                    utensílios de cozinha e eletrodomésticos.
                  </li>
                  <li>Lavanderia com tanque, varal e máquina de lavar.</li>
                  <li>
                    Quartos com escrivaninha, cadeira, guarda roupas, cama e
                    colchão.
                  </li>
                </ul>
              </div>
              <img src={SecondPhoto} alt="" />
            </div>

            <div className="servicos">
              <div>
                <h2>Serviços</h2>
                <p>
                  Para garantir a tranquilidade, conforto e segurança
                  apresentamos como solução a prestação dos seguintes serviços
                  já inclusos na mensalidade.
                </p>
                <ul>
                  <li className="ul-titulo">Veja abaixo:</li>
                  <li>
                    <span>Serviço de limpeza todos os dias -</span> Nossos
                    profissionais são treinados para limpar todo o imóvel
                    incluindo os quartos dos alunos.
                  </li>
                  <li>
                    <span>Internet (240MB) -</span> Temos o maior pacote de dados
                    disponível com roteadores de internet espalhados pela casa
                    para prover um serviço de internet de qualidade. Se
                    necessário, podemos oferecer cabos nos quartos.
                  </li>
                  <li>
                    <span>Manutenção das casas -</span> Temos uma equipe de
                    manutenção própria para resolver qualquer problema que
                    surgir.
                  </li>
                  <li>
                    <span>TV a cabo -</span> Todas as salas de televisão são
                    equipadas com televisores e pacote de TV a cabo.
                  </li>
                  <li>
                    <span>Vagas de garagem e bicicletário -</span> Para
                    automóveis, motos e bicicletas.
                  </li>
                  <li>
                    <span>Segurança 24 horas -</span> Empresa de segurança da
                    rua com serviço de acompanhamento do estudante na entrada e
                    saída do imóvel.
                  </li>
                  <li>
                    <span>Lavagem de roupas (cama e banho) -</span> Mediante a
                    pagamento
                  </li>
                </ul>
              </div>
              <img src={FirstPhoto} alt="" />
            </div>
          </section>
          <SocialMedia />
        </Content>
      </Container>
      <Footer />
    </>
  );
}

export default Sobre;
