import styled from "styled-components";

export const FooterDiv = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  margin-top: 2rem;
  a {
    color: white;
  }
  p {
    letter-spacing: 3px;
  }
`;
