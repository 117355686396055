import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  h1 {
    margin-top: 4rem;
    margin-bottom: 4rem;
    color: #ff6600;
    text-align: center;
    letter-spacing: 2px;
    font-size: 3rem;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
`;
