import { FaBed } from "react-icons/fa";
import { GiBunkBeds } from "react-icons/gi";
import { AiOutlineWifi } from "react-icons/ai";
import { CardContainer } from "./style";
import maid from "../../assets/home/maid.png";

function Casas({ infoHouse, infosConvenients }) {

  let min = Math.min(...infosConvenients.map((item) => item.price));

  return (
    <CardContainer>
      <img src={infoHouse.mainPhoto} alt="" className="main-photo" />
      <h2>{infoHouse.name}</h2>
      <div className="infos-container">
        <div className="infos-top">
          <div className="infos-top-first">
            <FaBed />
            <span>Vaga Individual</span>
          </div>

          <div className="infos-top-second">
            <GiBunkBeds />
            <span>Vagas Duplas</span>
          </div>
        </div>

        <div className="infos-bottom">
          <div className="infos-bottom-first">
            <img src={maid} alt="" />
            <span>Limpeza Inclusa</span>
          </div>

          <div className="infos-bottom-second">
            <AiOutlineWifi />
            <span>Internet Rápida</span>
          </div>
        </div>
      </div>

      <h3>
        {min === Infinity ? (
          <></>
        ) : (
          <span>
            A partir de <strong>R$ {min}.00</strong>
          </span>
        )}
      </h3>

    </CardContainer>
  );
}

export default Casas;
