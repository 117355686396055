import { Container } from "../../styles/style";
import { Content, Item } from "./style";
import Carousel from "react-elastic-carousel";
import NavBar from "../../components/Navbar";
import SocialMedia from "../../components/SocialMedia";
import icon1 from "../../assets/icons/icon1.svg";
import icon2 from "../../assets/icons/icon2.svg";
import icon3 from "../../assets/icons/icon3.svg";
import icon4 from "../../assets/icons/icon4.svg";
import icon6 from "../../assets/icons/icon6.svg";
import Footer from "../../components/Footer";

function Home({ carouselPhotosUrl }) {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 300, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 900, itemsToShow: 3 },
  ];

  return (
    <>
      <Container>
        <NavBar />
        <Content>
          <h1>
            A melhor localização para você estudante da <strong>UNICAMP</strong>
            . Conheça nossa <strong>STUDENT HOUSING.</strong>
          </h1>
          <h2>
            Casas totalmente mobiliadas, e preparadas para morar e estudar com
            tranquilidade, conforto e segurança.
          </h2>
          <div className="carousel-container">
            <Carousel breakPoints={breakPoints}>
              {carouselPhotosUrl.map((url) => (
                <Item key={url}>
                  <img src={url} alt="" />
                </Item>
              ))}
            </Carousel>
          </div>
          <h3>MAIS DE 10 ANOS DE EXPERIÊNCIA</h3>
          <section>
            <div>
              <img src={icon1} alt="" />
              <h4>Quem Somos</h4>
              <p>
                Há mais de 10 anos no mercado, mais de 500 alunos já passaram
                por nossas casas.
              </p>
            </div>
            <div>
              <img src={icon2} alt="" />
              <h4>Serviços</h4>
              <p>
                Limpeza, manutenção, administração. Encontre tudo que uma
                student house de qualidade pode oferecer.
              </p>
            </div>
            <div>
              <img src={icon3} alt="" />
              <h4>Infraestrutura</h4>
              <p>
                Casas totalmente mobiliadas que fornecem conforto, tranquilidade
                e segurança enquanto você estuda.
              </p>
            </div>
            <div>
              <img src={icon4} alt="" />
              <h4>Processo de Locação</h4>
              <p>Processo simples e sem burocracia. Pegue a chave na hora.</p>
            </div>
            <div>
              <img src={icon6} alt="" />
              <h4>Localização</h4>
              <p>
                More ao lado da UNICAMP. Não perca tempo com grandes
                deslocamentos.
              </p>
            </div>
          </section>
          <SocialMedia />
        </Content>
      </Container>
      <Footer />
    </>
  );
}

export default Home;
