import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import NossasCasas from "../pages/NossasCasas";
import Sobre from "../pages/Sobre";
import Contato from "../pages/Contato";
import Casa from "../pages/Casa";

function Routes({ infos }) {

  // Wait for data fetching from backend
  if (!infos) {
    return (
      <Switch>
        <Route exact path="/">
          <Home carouselPhotosUrl={[]}/>
        </Route>
        <Route path="/sobre">
          <Sobre />
        </Route>
        <Route path="/contato">
          <Contato />
        </Route>
      </Switch>
    );
  }

  // Data was fetched from backend

  const infosHouses = infos[0]
  const infosConvenients = infos[1]
  const carouselPhotosUrl = infos[2]
  
  return (
    <Switch>
      
      <Route exact path="/">
        <Home carouselPhotosUrl={carouselPhotosUrl}/>
      </Route>
      
      <Route path="/sobre">
        <Sobre />
      </Route>
      
      <Route path="/casas">
        <NossasCasas infosHouses={infosHouses} infosConvenients={infosConvenients}/>
      </Route>
      
      <Route path="/contato">
        <Contato />
      </Route>
      
      {infosHouses.map((infoHouse) => (
        <Route key={`${infoHouse.id}_0`} path={`/${infoHouse.nickname.replaceAll(" ", "")}`}>
          <Casa infoHouse={infoHouse} infosConvenients={infosConvenients}/>
        </Route>
      ))}

    </Switch>
  );
}

export default Routes;
