import { DivModal, Item, QuartoContainer } from "./style";
import { useState } from "react";
import Modal from "react-modal";
import "./modal.css";
import Carousel from "react-elastic-carousel";
import { AiOutlineClose } from "react-icons/ai";

Modal.setAppElement("#root");

function Quarto({ infoConvenient, title }) {
  
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  let whatsappAlugar = `https://api.whatsapp.com/send?phone=5511991861133&text=Ol%C3%A1%20Marcelo%2C%20acessei%20o%20site%20da%20Moradia%20do%20Estudante%2C%20e%20fiquei%20interessado%20no%20*${infoConvenient.name}*%20da%20casa%20*${title}*,%20poderia%20me%20ajudar%3F`;

  const breakPoints = [{ width: 1, itemsToShow: 1 }];

  return (
    <QuartoContainer>
      <div className="container-quarto">
        <div className="content-quarto">
          {infoConvenient.photos.length === 0 ? (
            <></>
          ) : (
            <>
              <img
                src={infoConvenient.photos[0]}
                alt="foto do quarto"
                onClick={openModal}
              />
              <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="example modal"
                overlayClassName="modal-overlay"
                className="modal-content"
              >
                <DivModal>
                  <div className="x-icon">
                    <AiOutlineClose
                      size={25}
                      onClick={closeModal}
                      color="#073763"
                    />
                  </div>
                  <div className="title-modal">
                    <h1>Fotos {infoConvenient.name}</h1>
                  </div>
                  <Carousel breakPoints={breakPoints}>
                    {infoConvenient.photos.map((item) => (
                      <Item key={item}>
                        <img src={item} alt="" />
                      </Item>
                    ))}
                  </Carousel>
                </DivModal>
              </Modal>
            </>
          )}
          <div className="info-quarto">
            <h1>{infoConvenient.name}</h1>
            <p>{infoConvenient.description}</p>
            <span>R$ {infoConvenient.price}.00</span>
          </div>

          {infoConvenient.reserved === true ? (
            <div className="btn-quarto">
              <span className="alugado">Alugado</span>
            </div>
          ) : (
            <a
              className="btn-quarto"
              target="_blank"
              rel="noreferrer"
              href={whatsappAlugar}
            >
              <span className="alugar">Alugar</span>
            </a>
          )}
        </div>
      </div>
    </QuartoContainer>
  );
}

export default Quarto;
