import { Container } from "../../styles/style";
import { Content, Item } from "./style";
import Navbar from "../../components/Navbar";
import SocialMedia from "../../components/SocialMedia";
import Carousel from "react-elastic-carousel";
import Quarto from "../../components/Quarto";
import Footer from "../../components/Footer";

function Casa({ infoHouse, infosConvenients }) {

  const breakPoints = [{ width: 300, itemsToShow: 1 }];

  let facilitiesList = [];
  const facilities = infoHouse.facilities.split(";");
  for (let i = 0; i < facilities.length; i++) {
    facilitiesList.push(facilities[i].trim());
  }

  const addressLink = infoHouse.address.replaceAll(" ", "%20");
  const iframeSource =
    "https://maps.google.com/maps?width=100%&height=600&hl=en&q=" +
    addressLink +
    "(My%20Business%20Name)&t=&z=14&ie=UTF8&iwloc=B&output=embed";

  // Create list of house convenient IDs
  let convenientsIDs = []
  infoHouse.convenients.map((convenient) => {
    convenientsIDs.push(convenient._path.segments[1])
  })
  // Filter information from convenients in the house
  let convenients = []
  infosConvenients.map((convenient) => {
    if (convenientsIDs.indexOf(convenient.id) > -1) {
      convenients.push(convenient)
    }
  })

  return (
    <>
      <Container>
        <Content>
          <Navbar />
          <section className="apresentacao">
            <h1>{infoHouse.name}</h1>
            <p>
              Casa totalmente mobiliada e preparada para morar e estudar com
              tranquilidade e conforto.
            </p>
            <div className="carousel-container">
              {infoHouse.generalPhotos.length === 0 ? (
                <></>
              ) : (
                <Carousel breakPoints={breakPoints}>
                  {infoHouse.generalPhotos.map((item) => (
                    <Item key={item}>
                      <img src={item} alt="" />
                    </Item>
                  ))}
                </Carousel>
              )}
            </div>
            <h2>Informações</h2>
            <div className="infos">
              <div className="map-casa">
                <iframe
                  src={iframeSource}
                  width="100%"
                  height="100%"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  frameBorder="0"
                  title="location"
                />
              </div>
              <div className="infos-text">
                <p>{infoHouse.description}</p>
                <h3>Facilidades</h3>
                <ol>
                  {facilitiesList.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ol>
              </div>
            </div>
          </section>
          <section className="quartos">
            {convenients.map((convenient) =>
              <Quarto key={convenient.id} infoConvenient={convenient} title={infoHouse.name} />
            )}
          </section>
          <SocialMedia />
        </Content>
      </Container>
      <Footer />
    </>
  );
}

export default Casa;
