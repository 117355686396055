import { useEffect, useState } from "react";
import api from "./api";
import Routes from "./routes";
import GlobalStyle from "./styles/global";

function App() {

  const [infos, setInfos] = useState(null);

  useEffect(() => {
    const getInfos = async () => {
      const response = await api.get("getInfos");
      setInfos(response.data);
    }
    getInfos();
  }, []);

  return (
    <>
      <GlobalStyle />
      <Routes infos={infos}/>
    </>
  );
}

export default App;
