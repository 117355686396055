import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  h1 {
    margin-top: 4rem;
    color: #ff6600;
    text-align: center;
    letter-spacing: 2px;
    font-size: 3rem;
  }
  section {
    margin-top: 3rem;

    .quem-somos {
      display: flex;
      align-items: center;

      @media (max-width: 1040px) {
        img {
          width: 400px;
          height: 400px;
        }
      }

      @media (max-width: 886px) {
        img {
          width: 300px;
          height: 300px;
        }
      }

      @media (max-width: 786px) {
        flex-direction: column;
        img {
          width: 350px;
          height: 350px;
        }
      }

      @media (max-width: 350px) {
        img {
          width: 280px;
          height: 280px;
        }
      }

      div {
        margin-left: 1rem;
        h2 {
          margin-bottom: 3rem;
          color: #073763;
          font-size: 1.8rem;
        }
        p {
          color: #073763;
          margin-bottom: 1rem;
          line-height: 1.5rem;
          font-size: 1.2rem;
        }
        span {
          font-weight: 600;
        }
        @media (max-width: 1165px) {
          p {
            font-size: 1rem;
          }
        }
        @media (max-width: 786px) {
          margin-left: 0;
          h2 {
            text-align: center;
          }
        }
        @media (max-width: 935px) {
          p {
            font-size: 0.9rem;
          }
        }
      }
    }

    .infraestrutura {
      display: flex;
      flex-direction: row-reverse;

      @media (max-width: 1040px) {
        align-items: center;
        img {
          width: 400px;
          height: 400px;
        }
      }

      @media (max-width: 886px) {
        img {
          width: 300px;
          height: 300px;
        }
      }

      @media (max-width: 786px) {
        flex-direction: column;
        img {
          width: 350px;
          height: 350px;
        }
      }

      @media (max-width: 350px) {
        img {
          width: 280px;
          height: 280px;
        }
      }

      div {
        margin-left: 2rem;
        h2 {
          margin-top: 3rem;
          margin-bottom: 3rem;
          color: #073763;
          font-size: 1.8rem;
        }
        p,
        li {
          color: #073763;
          line-height: 1.5rem;
          font-size: 1.2rem;
        }
        p {
          margin-bottom: 1.2rem;
        }
        .ul-titulo {
          font-size: 1.35rem;
          font-weight: 600;
          color: #ff6600;
        }
        li {
          margin-bottom: 0.8rem;
        }
        span {
          font-weight: 600;
        }
        @media (max-width: 1165px) {
          p,
          li {
            font-size: 1rem;
          }
          .ul-titulo {
            font-size: 1.2rem;
            font-weight: 500;
          }
        }
        @media (max-width: 786px) {
          margin-left: 0;
          h2 {
            text-align: center;
          }
        }
        @media (max-width: 935px) {
          p,
          li {
            font-size: 0.9rem;
          }
          .ul-titulo {
            font-size: 1.1rem;
          }
        }
      }
    }

    .servicos {
      display: flex;
      margin-bottom: 3rem;
      align-items: center;
      @media (max-width: 1290px) {
        img {
          width: 500px;
          height: 500px;
        }
      }

      @media (max-width: 1040px) {
        img {
          width: 450px;
          height: 450px;
        }
      }

      @media (max-width: 886px) {
        img {
          width: 300px;
          height: 300px;
        }
      }

      @media (max-width: 786px) {
        flex-direction: column;
        img {
          width: 350px;
          height: 350px;
        }
      }

      @media (max-width: 350px) {
        img {
          width: 280px;
          height: 280px;
        }
      }

      div {
        margin-left: 1rem;
        h2 {
          margin-top: 3rem;
          margin-bottom: 3rem;
          color: #073763;
          font-size: 1.8rem;
        }
        p,
        li {
          color: #073763;
          line-height: 1.5rem;
          font-size: 1.2rem;
        }
        p {
          margin-bottom: 1.2rem;
        }
        .ul-titulo {
          font-size: 1.35rem;
          font-weight: 600;
          color: #ff6600;
        }
        li {
          margin-bottom: 1rem;
          span {
            font-size: 1.30rem;
            font-weight: 600;
          }
        }
        @media (max-width: 1270px) {
          p,
          li {
            font-size: 1rem;
          }
          li {
            span {
              font-size: 1.1rem;
            }
          }
        }
        @media (max-width: 786px) {
          margin-left: 0;
          h2 {
            text-align: center;
          }
        }
        @media (max-width: 935px) {
          .ul-titulo {
            font-size: 1.2rem;
          }
          p,
          li {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
`;
