import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  .carousel-container {
    width: 95%;
    margin: 0 auto;

    .rec-arrow-right,
    .rec-arrow-left,
    .rec-arrow {
      background-color: #ff6600;
    }

    @media (max-width: 768px) {
      .rec-arrow-right,
      .rec-arrow-left {
        display: none;
      }
    }

    .rec-arrow:hover,
    .sc-hLBbgP,
    .jSwPKj,
    .cHXGVU,
    .sc-hLBbgP.jSwPKj.rec.rec-arrow.rec.rec-arrow-right,
    .sc-hLBbgP.cHXGVU.rec.rec-arrow.rec.rec-arrow-right {
      color: #073763;
      background-color: #ff6600;
    }

    .rec-dot_active {
      background-color: #ff6600;
      box-shadow: 0 0 1px 3px rgb(7, 55, 99);
    }
    .rec-dot:hover {
      box-shadow: 0 0 1px 3px rgb(7, 55, 99);
    }
    .PKIgV:hover:enabled,
    .PKIgV:focus:enabled,
    .hoTAxi:hover:enabled,
    .hoTAxi:focus:enabled {
      color: #073763;
      background-color: #ff6600;
    }
  }

  h1 {
    color: #4e5f70;
    font-size: 1.6rem;
    margin: 0;
    margin-top: 3rem;
    margin-bottom: 1.2rem;
    text-align: center;
  }
  strong {
    color: #ff6600;
    font-size: 1.8rem;
  }
  h2 {
    color: #4e5f70;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.8rem;
    letter-spacing: 1px;
    color: #ff6600;
    font-weight: 700;
  }
  section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    div {
      width: calc((100% - 6rem) / 3);
      height: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 130px;
        height: 130px;
        margin-bottom: 1rem;
      }
      h4 {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: #ff6600;
      }
      p {
        text-align: center;
        font-size: 0.95rem;
        color: #073763;
      }

      @media (max-width: 1000px) {
        width: calc((100% - 4rem) / 2);
      }

      @media (max-width: 550px) {
        width: 80%;
      }
    }
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  img {
    width: 360px;
    height: 250px;
    border-radius: 8px;
    @media (max-width: 1320px) {
      width: 330px;
      height: 220px;
    }
    @media (max-width: 1232px) {
      width: 310px;
      height: 200px;
    }
    @media (max-width: 1165px) {
      width: 290px;
      height: 180px;
    }
    @media (max-width: 1105px) {
      width: 310px;
    }
    @media (max-width: 826px) {
      width: 290px;
      height: 170px;
    }
    @media (max-width: 786px) {
      width: 265px;
      height: 155px;
    }
    @media (max-width: 735px) {
      width: 270px;
      height: 150px;
    }
    @media (max-width: 680px) {
      width: 260px;
    }
    @media (max-width: 640px) {
      width: 240px;
      height: 140px;
    }
    @media (max-width: 605px) {
      width: 230px;
      height: 130px;
    }
    @media (max-width: 579px) {
      width: 330px;
      height: 230px;
    }
    @media (max-width: 430px) {
      width: 290px;
      height: 190px;
    }
    @media (max-width: 375px) {
      width: 260px;
      height: 180px;
    }
    @media (max-width: 335px) {
      width: 230px;
      height: 130px;
    }
  }
`;
