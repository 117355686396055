import { Container } from "../../styles/style";
import { Content } from "./style";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Casas from "../../components/Casas";
import SocialMedia from "../../components/SocialMedia";
import Footer from "../../components/Footer";

function NossasCasas({ infosHouses, infosConvenients }) {

  infosHouses.sort(function(a, b) {
    if (a.houseId < b.houseId) {
      return -1
    }
    else {
      return true
    }
  })

  return (
    <>
      <Container>
        <Content>
          <Navbar />
          <h1>Nossas Casas</h1>
          <section>
            {infosHouses.map((house) => {
              // Create list of house convenient IDs
              let convenientsIDs = []
              house.convenients.map((convenient) => {
                convenientsIDs.push(convenient._path.segments[1])
              })
              // Filter information from convenients in the house
              let convenients = []
              infosConvenients.map((convenient) => {
                if (convenientsIDs.indexOf(convenient.id) > -1) {
                  convenients.push(convenient)
                }
              })
              // Return house visualization
              return <Link key={house.id} to={`${house.nickname.replaceAll(" ", "")}`}>
                <Casas infoHouse={house} infosConvenients={convenients}/>
              </Link>
            })}
          </section>
          <SocialMedia />
        </Content>
      </Container>
      <Footer />
    </>
  );
}

export default NossasCasas;
