import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  h1 {
    margin-top: 4rem;
    margin-bottom: 4rem;
    color: #ff6600;
    text-align: center;
    letter-spacing: 2px;
    font-size: 3rem;
  }
  section {
    margin-top: 3rem;
    width: 100%;

    h2 {
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
    }

    .form-button {
      width: 170px;
      height: 40px;
      font-size: 0.9rem;
      letter-spacing: 1.5px;
      font-weight: 600;
      outline: none;
      border-radius: 6px;
      border: none;
      background-color: #073763;
      color: #ffffff;
      cursor: pointer;
    }

    .opcoes-contato {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }

    .numero {
      font-size: 2rem;
      color: #073763;
      text-align: center;

      @media (max-width: 457px) {
        font-size: 1.5rem;
      }

      @media (max-width: 351px) {
        font-size: 1.3rem;
      }
      @media (max-width: 307px) {
        font-size: 1.1rem;
      }
    }

    .aviso {
      margin-top: 0.5rem;
      font-size: 1.4rem;
      color: #ff6600;
      text-align: center;

      @media (max-width: 554px) {
        font-size: 1.2rem;
      }
      @media (max-width: 351px) {
        font-size: 1.1rem;
      }
      @media (max-width: 307px) {
        font-size: 0.9rem;
      }
    }

    .aviso:hover {
      text-decoration: underline;
    }
  }
`;
