import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    font-family: 'Plus Jakarta Sans', sans-serif;

    @media (max-width: 1316px) {
        margin-left: 1rem;
        padding-right: 2rem;
    }
`