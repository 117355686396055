import styled from "styled-components";

export const CardContainer = styled.div`
  width: ((100%-6rem)/4);
  height: 400px;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 1);
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  &:hover,
  &:focus {
    transform: scale(1.05);
    transition: 0.25s;
  }

  @media (max-width: 1203px) {
    width: ((100%-2rem)/2);
  }

  h2 {
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1rem;
    margin-top: 3rem;
    strong {
      color: #ff6600;
    }
  }

  .main-photo {
    width: 265px;
    height: 160px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin-bottom: 25px;
  }

  .infos-container {
    .infos-top {
      display: flex;
      justify-content: space-around;
      margin-bottom: 12px;
      .infos-top-first {
        display: flex;
        align-items: center;
        span {
          margin-left: 7px;
          font-size: 0.7rem;
          font-weight: 600;
        }
      }
      .infos-top-second {
        display: flex;
        align-items: center;
        span {
          margin-left: 15px;
          font-size: 0.7rem;
          font-weight: 600;
        }
      }
    }
    .infos-bottom {
      display: flex;
      justify-content: space-around;
      .infos-bottom-first {
        display: flex;
        align-items: center;
        img {
          width: 1rem;
        }
        span {
          margin-left: 7px;
          font-size: 0.7rem;
          font-weight: 600;
        }
      }
      .infos-bottom-second {
        display: flex;
        align-items: center;
        span {
          margin-left: 7px;
          font-size: 0.7rem;
          font-weight: 600;
        }
      }
    }
  }
`;
