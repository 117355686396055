import Navbar from "../../components/Navbar";
import { Container } from "../../styles/style";
import { Content } from "./style";
import { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SocialMedia from "../../components/SocialMedia";
import api from "../../api";
import Footer from "../../components/Footer";

function Contato() {
  const form = useRef();

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const formSchema = yup.object().shape({
    name: yup.string().required("Campo Obrigatório"),
    subject: yup.string().required("Campo Obrigatório"),
    email: yup.string().email("Email inválido").required("Campo Obrigatório"),
    message: yup.string().required("Campo Obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const sendEmail = (e) => {
    api
      .get("sendEmail", {
        params: {
          params: {
            name: name,
            subject: subject,
            email: email,
            message: message,
          },
        },
      })
      .then((result) => {
        if (result.data === true) {
          toast.success("Email enviado com sucesso", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Ops, algo deu errado!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        reset({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      });
  };

  return (
    <>
      <Container>
        <Content>
          <Navbar />
          <h1>Contato</h1>
          <section>
            <h2>Mande-nos uma mensagem!</h2>
            <form onSubmit={handleSubmit(sendEmail)} ref={form}>
              <Input
                label="Nome"
                placeholder="Seu nome"
                name="name"
                register={register}
                error={errors.name?.message}
                autoComplete="off"
                onChange={(event) => setName(event.target.value)}
              />

              <Input
                label="Assunto"
                placeholder="Assunto do email"
                name="subject"
                register={register}
                error={errors.subject?.message}
                autoComplete="off"
                onChange={(event) => setSubject(event.target.value)}
              />

              <Input
                label="Email"
                placeholder="Seu email"
                name="email"
                register={register}
                error={errors.email?.message}
                onChange={(event) => setEmail(event.target.value)}
              />

              <Input
                label="Mensagem"
                placeholder="Sua mensagem"
                name="message"
                register={register}
                error={errors.message?.message}
                autoComplete="off"
                onChange={(event) => setMessage(event.target.value)}
              />

              <input
                type="submit"
                value="Enviar mensagem"
                className="form-button"
              />
            </form>

            <h2>No Whatsapp:</h2>
            <div className="opcoes-contato">
              <span className="numero">Pelo número: (11) 99186-1133</span>
              <a
                className="aviso"
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=5511991861133&text=Ol%C3%A1%20Marcelo%2C%20acessei%20o%20site%20da%20Moradia%20do%20Estudante%2C%20poderia%20me%20ajudar%20sobre%20dúvidas%20que%20tenho%3F"
              >
                ou clique aqui!
              </a>
            </div>
          </section>
          <SocialMedia />
        </Content>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Container>
      <Footer />
    </>
  );
}

export default Contato;
