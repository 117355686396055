import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { SocialContainer } from "./style";

function SocialMedia() {
  return (
    <SocialContainer>
      <a
        className="whatsapp"
        target="_blank"
        rel="noreferrer"
        href="https://api.whatsapp.com/send?phone=5511991861133&text=Ol%C3%A1%20Marcelo%2C%20acessei%20o%20site%20da%20Moradia%20do%20Estudante%2C%20poderia%20me%20ajudar%20sobre%20dúvidas%20que%20tenho%3F"
      >
        <IoLogoWhatsapp />
      </a>
      <footer>
        <div>
          <a
            href="https://www.facebook.com/moradiadoestudante/?ref=bookmarks"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillFacebook style={{ fontSize: "3rem", color: "#ff6600" }} />
          </a>
          <a
            href="https://www.instagram.com/moradiadoestudante/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram style={{ fontSize: "3rem", color: "#ff6600" }} />
          </a>
        </div>
      </footer>
    </SocialContainer>
  );
}

export default SocialMedia;
