import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  .apresentacao {
    margin-top: 4rem;
    h1 {
      color: #ff6600;
      text-align: center;
      letter-spacing: 2px;
      font-size: 3rem;
      margin-bottom: 1.5rem;

      @media (max-width: 746px) {
        font-size: 2.5rem;
      }
      @media (max-width: 531px) {
        font-size: 2.1rem;
      }
    }
    p {
      font-size: 1.45rem;
      color: #073763;
      text-align: center;
      @media (max-width: 746px) {
        font-size: 1.3rem;
      }
    }

    .carousel-container {
      width: 95%;
      margin: 0 auto;
      margin-top: 2rem;

      @media (max-width: 768px) {
        .rec-arrow-right,
        .rec-arrow-left {
          display: none;
        }
      }

      .rec-arrow,
      .rec-arrow-right,
      .rec-arrow-left {
        background-color: #ff6600;
      }

      .rec-arrow:hover {
        color: #073763;
        background-color: #ff6600;
      }

      .rec-dot_active {
        background-color: #ff6600;
        box-shadow: 0 0 1px 3px rgb(7, 55, 99);
      }
      .rec-dot:hover {
        box-shadow: 0 0 1px 3px rgb(7, 55, 99);
      }
      .hoTAxi:hover:enabled,
      .hoTAxi:focus:enabled,
      .sc-hLBbgP,
      .PKIgV:hover:enabled,
      .PKIgV:focus:enabled,
      .cHXGVU {
        color: #073763;
        background-color: #ff6600;
      }
    }

    h2 {
      color: #ff6600;
      text-align: center;
      letter-spacing: 2px;
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .infos {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1010px) {
        flex-direction: column-reverse;
        align-items: center;
      }

      .map-casa {
        width: 600px;
        height: 400px;
        @media (max-width: 645px) {
          width: 500px;
          height: 300px;
        }

        @media (max-width: 540px) {
          width: 400px;
        }
        @media (max-width: 435px) {
          width: 300px;
        }

        @media (max-width: 330px) {
          width: 250px;
        }
      }

      .infos-text {
        width: 600px;
        margin-bottom: 1.5rem;

        @media (max-width: 1275px) {
          width: 450px;
        }

        @media (max-width: 1105px) {
          width: 350px;
        }

        @media (max-width: 1010px) {
          width: 600px;
        }

        @media (max-width: 635px) {
          width: 450px;
        }

        @media (max-width: 485px) {
          width: 350px;
        }

        @media (max-width: 375px) {
          width: 250px;
        }

        p {
          font-size: 1.3rem;
          line-height: 1.8rem;
          text-align: justify;
          @media (max-width: 746px) {
            font-size: 1.2rem;
          }
        }
        h3 {
          text-align: center;
          font-size: 1.8rem;
          color: #ff6600;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        ol {
          li {
            list-style: inside;
            margin-bottom: 0.5rem;
            color: #073763;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .quartos {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1.5rem;
    @media (max-width: 670px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 8px;
    height: 300px;
    max-width: 90%;
    @media (max-width: 780px) {
      height: 250px;
    }
    @media(max-width: 600px) {
      height: 220px;
    }
    @media (max-width: 530px) {
      height: 195px;
    }
    @media(max-width: 475px) {
      height: 170px;
    }
    @media (max-width: 410px) {
      height: 160px;
    }
    @media (max-width: 395px) {
      height: 150px;
    }
    @media (max-width: 355px) {
      height: 120px;
    }
  }
`;
