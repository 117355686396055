import { Box } from "@mui/system";
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/home/logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const pages = [
    { page: "Home", route: "/" },
    { page: "Sobre", route: "/sobre" },
    { page: "Nossas Casas", route: "/casas" },
    { page: "Contato", route: "/contato" },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
      <AppBar position="static" sx={{ backgroundColor: "white", boxShadow: 0 }}>
        <Toolbar>
          <Typography
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <Link to="/">
              <img src={Logo} alt="Moradia do Estudante" />
            </Link>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#ff6600" }} />
            </IconButton>
            <Typography sx={{ height: 100 }}>
              <Link to="/">
                <img src={Logo} alt="Moradia do Estudante" />
              </Link>
            </Typography>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.page} onClick={handleCloseNavMenu}>
                  <Link to={page.route}>
                    <Typography
                      textAlign="center"
                      component={Link}
                      to={page.route}
                      sx={{
                        color: "#073763",
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                        fontWeight: "600",
                        letterSpacing: "2px",
                      }}
                    >
                      {page.page}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Link to={page.route} key={page.page}>
                <Button
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.route}
                  sx={{
                    my: 2,
                    color: "#073763",
                    display: "block",
                    fontFamily: "Plus Jakarta Sans, sans-serif",
                    fontWeight: "600",
                    letterSpacing: "2px",
                  }}
                >
                  {page.page}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
