import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
        list-style: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }
    html, body, #root {
        overflow-x: hidden;
    }
    button {
        cursor: pointer;
    }
    a {
        text-decoration: none;
        color: black;
    }
`

export default GlobalStyle