import { FooterDiv } from "./style";

function Footer() {
  return (
    <FooterDiv>
      <a
        href="https://www.instagram.com/goat.slts"
        target="_blank"
        rel="noreferrer"
      >
        <p>Powered by Go-At Solutions</p>
      </a>
    </FooterDiv>
  );
}

export default Footer;
