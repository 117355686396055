import styled from "styled-components";

export const SocialContainer = styled.div`
  .whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 3rem;
    right: 4rem;
    background-color: #25d366;
    color: white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    z-index: 1000;

    @media (max-width: 1150px) {
      right: 2rem;
    }
  }
  footer {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: flex-start;
    div {
      background-color: #073763;
      border-radius: 50px;
      width: 135px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
`;
