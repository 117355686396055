import styled, { css } from "styled-components";

export const Container = styled.div`
  text-align: left;
  font-family: "Inter", sans-serif;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 10px;
  margin-top: 1.5rem;
  span {
    font-size: 1rem;
    font-weight: 600;
    span {
      ${(props) =>
        props.isErrored &&
        css`
          color: #c53030;
          font-style: italic;
        `}
    }
  }
  input {
    width: 297px;
    height: 48px;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 1rem;
    background-color: #073763;
    font-size: 1rem;
    color: white;
  }
  input::placeholder {
    opacity: 0.7;
    color: white;
  }
  input:focus::placeholder {
    opacity: 1;
  }
`;
