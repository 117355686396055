import styled from "styled-components";

export const QuartoContainer = styled.div`
  @media (max-width: 670px) {
    margin-top: 2rem;
  }
  margin-bottom: 1rem;
  .container-quarto {
    border-radius: 7px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(7, 55, 99, 1);
    -moz-box-shadow: 0px 0px 11px 0px rgba(7, 55, 99, 1);
    box-shadow: 0px 0px 11px 0px rgba(7, 55, 99, 1);

    width: 280px;
    height: 350px;
    display: flex;
    justify-content: space-between;

    .content-quarto {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      img {
        width: 280px;
        height: 135px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        cursor: pointer;
      }
      .info-quarto {
        margin: 15px;
        h1,
        span {
          color: #073763;
        }
        span {
          font-weight: 600;
        }
        p {
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #ff6600;
        }
      }
      .btn-quarto {
        background-color: #073763;
        border-radius: 6px;
        font-size: 1.3rem;
        height: 35px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 10px;
        span {
          color: #ffffff;
          letter-spacing: 2px;
          /* color: #ff6600; */
        }
        .alugado {
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const DivModal = styled.div`
  width: 100%;
  height: 100%;
  .x-icon {
    float: right;
    cursor: pointer;
  }
  .title-modal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    h1 {
      font-size: 2rem;
      color: #ff6600;
    }
  }
  @media (max-width: 768px) {
    .rec-arrow-right,
    .rec-arrow-left {
      display: none;
    }
  }

  .rec-arrow,
  .rec-arrow-right,
  .rec-arrow-left {
    background-color: #ff6600;
  }

  .rec-arrow:hover {
    color: #073763;
    background-color: #ff6600;
  }

  .rec-dot_active {
    background-color: #ff6600;
    box-shadow: 0 0 1px 3px rgb(7, 55, 99);
  }
  .rec-dot:hover {
    box-shadow: 0 0 1px 3px rgb(7, 55, 99);
  }
  .hoTAxi:hover:enabled,
  .hoTAxi:focus:enabled,
  .sc-hLBbgP,
  .PKIgV:hover:enabled,
  .PKIgV:focus:enabled,
  .cHXGVU {
    color: #073763;
    background-color: #ff6600;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 8px;
    height: 250px;
    max-width: 95%;
    @media (max-width: 630px) {
      height: 235px;
    }
    @media (max-width: 580px) {
      height: 215px;
    }
    @media (max-width: 520px) {
      height: 200px;
    }
    @media (max-width: 480px) {
      height: 180px;
    }
    @media (max-width: 415px) {
      height: 160px;
    }
    @media (max-width: 370px) {
      height: 140px;
    }
  }
`;
